import React from 'react'
import './style.css';
import Img from './img/Petro Yem Med logo.png'
const Logo = () => {
  return (
    <div className='logo' id='logo'>
      <img src={Img} alt="" />
    </div>
  )
}

export default Logo