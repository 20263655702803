import React from "react";
import "./style.css";
import HowWeAre from "./../HowWeAre/HowWeAre";
import OurServices from "./../OurServices/OurServices";
import OurProject from "./../OurProject/OurProject";
import OurSupplies from "./../OurSupplies/OurSupplies";
import OurClients from "./../OurClients/OurClients";
import WhyUs from "./../WhyUs/WhyUs";
import ContactUs from "./../ContactUs/ContactUs";
import Footer from "./../Footer/Footer";
import Logo from "./../Logo/Logo";
import Home from "./../Home/Home";
const Sidebar = ({ children }) => {
  const active = () => {
    let myLinks = document.querySelectorAll(".slider .navItem a");
    myLinks.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.stopPropagation();
        handleActive(e);
      });
    });

    function handleActive(e) {
      // Remove Active Class From All Children
      e.target.parentElement.querySelectorAll(".active").forEach((element) => {
        element.classList.remove("active");
      });

      // Add Class Active
      e.target.classList.add("active");
    }
    hide();
  };

  const show = () => {
    var slider = document.getElementById("slider");
    var open = document.getElementById("open");
    var close = document.getElementById("close");
    var logo = document.getElementById("logo");

    slider.classList.add("show");
    slider.classList.remove("hide");

    open.style.display = "none";
    logo.style.display = "none";
    close.style.display = "block";
    slider.style.display = "block";
  };
  const hide = () => {
    var slider = document.getElementById("slider");
    var close = document.getElementById("close");
    var open = document.getElementById("open");
    var logo = document.getElementById("logo");

    slider.classList.add("hide");
    slider.classList.remove("show");

    close.style.display = "none";
    logo.style.display = "block";
    open.style.display = "block";
  };
  return (
    <div className="row all">
      <div className="home col-12  ">
        <p
          className="open"
          id="open"
          onTouchMove={() => show()}
          onClick={() => show()}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M2 13L2 17L48 17L48 13 Z M 2 23L2 27L48 27L48 23 Z M 2 33L2 37L48 37L48 33 Z M 18 41C17.597656 41 17.21875 41.25 17.0625 41.625C16.90625 42 16.996094 42.433594 17.28125 42.71875L24.28125 49.71875C24.476563 49.914063 24.742188 50 25 50C25.257813 50 25.523438 49.914063 25.71875 49.71875L32.71875 42.71875C33.003906 42.433594 33.09375 42 32.9375 41.625C32.785156 41.25 32.402344 41 32 41Z" />
          </svg>
        </p>

        <p
          className="close"
          id="close"
          onClick={() => hide()}
          onTouchMove={() => hide()}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M2 13L2 17L48 17L48 13 Z M 2 23L2 27L48 27L48 23 Z M 2 33L2 37L48 37L48 33 Z M 18 41C17.597656 41 17.21875 41.25 17.0625 41.625C16.90625 42 16.996094 42.433594 17.28125 42.71875L24.28125 49.71875C24.476563 49.914063 24.742188 50 25 50C25.257813 50 25.523438 49.914063 25.71875 49.71875L32.71875 42.71875C33.003906 42.433594 33.09375 42 32.9375 41.625C32.785156 41.25 32.402344 41 32 41Z" />
          </svg>
        </p>
        <div className="slider" id="slider">
          <div className="navItem" id="navItem">
            <a
              href="#home"
              className="active"
              onClick={() => {
                active();
              }}
              onTouchMove={() => hide()}
            >
              Home
            </a>

            <a
              href="#whoWeAre"
              onClick={() => {
                active();
              }}
            >
              Who We Are
            </a>

            <a
              href="#ourService"
              onClick={() => {
                active();
              }}
            >
              Our Services
            </a>

            <a
              href="#ourProject"
              onClick={() => {
                active();
              }}
            >
              Our Projects
            </a>

            <a
              href="#ourSupplies"
              onClick={() => {
                active();
              }}
            >
              Our Supplies
            </a>

            <a
              href="#ourClients"
              onClick={() => {
                active();
              }}
            >
              Our Clients
            </a>

            <a
              href="#whyUs"
              onClick={() => {
                active();
              }}
            >
              Why Us
            </a>

            <a
              href="#contactUs"
              onClick={() => {
                active();
              }}
            >
              Contact Us
            </a>

            <a
              href="http://www.petroyemmed.com/webmail"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                active();
              }}
            >
              Email
            </a>
          </div>
        </div>

        <div className="col">
          <div className="col-12">
            <Logo />
            <Home />
            <HowWeAre />
            <OurServices />
            <OurProject />
            <OurSupplies />
            <OurClients />
            <WhyUs />
            <ContactUs />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
