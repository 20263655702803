import React from "react";
import "./style.css";
import IMG from "./image/DSC01386.jpg";
import IMG2 from "./image/111.jpg";
import IMG3 from "./image/DSC00564.jpg";
import Carousel from "react-bootstrap/Carousel";

const Home = ({ hide }) => {
  return (
    <Carousel fade id="home">
      <Carousel.Item>
        <img className="d-block w-100" src={IMG} alt="First slide" />
        <Carousel.Caption>
          <h3>Giants of Creativity</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={IMG3} alt="Second slide" />

        <Carousel.Caption>
          <h3>Over 30 years of experience</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={IMG2} alt="Third slide" />

        <Carousel.Caption>
          <p>
            <span className="h4">
              PETRO YEM MED offers a wide range of services{" "}
            </span>
            <span className="h4 sm-size">including : </span>
            <span className="w-75 m-auto d-block sm-size mt-3">
              Construction, Maintenance, Surveying for Oil &amp; Gas projects,
              Engineering Project management, Equipment and Materials supply
              &amp; Technical manpower supply
            </span>
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Home;
