import React from "react";
import "./style.css";
import Img from "./ourProjectImage/aref 191.JPG";
import Img1 from "./ourProjectImage/8.jpg";
import Img2 from "./ourProjectImage/aref 212.JPG";
import Img3 from "./ourProjectImage/DSC00508.jpg";
import Img4 from "./ourProjectImage/DSC00564.jpg";
import Img5 from "./ourProjectImage/DSC02845.jpg";
import Img6 from "./ourProjectImage/7.png";
import Img7 from "./ourProjectImage/99.jpg";
import Img8 from "./ourProjectImage/9.jpg";
import Img9 from "./ourProjectImage/10.jpg";
import Img11 from "./ourProjectImage/12.jpg";
import Img12 from "./ourProjectImage/13.jpg";
import Img14 from "./ourProjectImage/15.jpg";
import Img15 from "./ourProjectImage/16.jpg";
import Img16 from "./ourProjectImage/17.jpg";
import Img17 from "./ourProjectImage/18.jpg";
import Img18 from "./ourProjectImage/new.jpg";
import Img19 from "./ourProjectImage/111.jpg";

const OurProject = () => {
  return (
    <>
      <div className="ourProject" id="ourProject">
        <div className="content">
          <h2 className="text-center">OUR PROJECTS</h2>

          <hr />

          <div className="boxes">
            <div className="box">
              <img src={Img1} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  16 INCH GAS PIPELINE FROM RAYDAN TO SAFER CPU FACILITY
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  8 INCH GAS PIPELINE CONSTRUCTION FROM AL-SHURA TO SAFER CPU
                  FACILITY
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img2} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  CONSTRUCTION , COMMISSIONING AND START UP OF NEW EXPANSION
                  TRAIN AT SAFER CPU FACILITY IN 2012
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img3} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  CONSTRUCTION OF NEW PIPERACK FOR SAFER NEW EXPANSION
                  PRODUCTION TRAIN IN 2012
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img4} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  FABRICATION AND INSTALLATION OF 36 SLOTS MANIFOLD FOR SEPOC
                  NEW EXPANSION PRODUCTION TRAIN IN 2012
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img5} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  CONSTRUCTION OF RAYDAN NEW PRODUCTION MANIFOLD AND FLARE
                  HEADER IN 2013
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img6} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  ELECTRICAL PROJECT FOR SEPOC – YGC LPG EXPANSION IN 2012
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img7} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  16 INCH PIG RECEIVER AT SAFER CPU FACILITY
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img8} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">RAYDAN HOUSING PROJECT</div>
              </div>
            </div>

            <div className="box">
              <img src={Img9} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">CMG MAINTENANCE OFFICES BUILDING</div>
              </div>
            </div>

            <div className="box">
              <img src={Img11} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  TOTAL BLOCK -10B NEW EXPORT PIPELINE PROJECT
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img12} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  SHAROOUF WELL LOCATION PROJECT JOINTLY WITH DOVE ENERGY
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img14} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">EPF-3 HEATER REPAIR (H-101)</div>
              </div>
            </div>

            <div className="box">
              <img src={Img15} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  CPU UPSTREAM PROJECT JOINTLY WITH YLNG
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img16} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  TASLA WELL LOCATION PROJECT JOINTLY WITH OIL SEARCH
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img17} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  YGC ADDITIONAL LPG STORAGE &amp; LOADING
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img18} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  12 INCH GAS PIPELINE 28 KILOMETER FROM KPU TO SAFER CPU IN
                  2018
                </div>
              </div>
            </div>

            <div className="box">
              <img src={Img19} alt="" className="img1" />

              <div className="overlay">
                <hr />

                <div className="text">
                  SEPOC TANK (T-1301) CONCRETE FOUNDATION
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurProject;
