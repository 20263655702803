import './App.css';
import Sidebar from './Component/Sidebar/Sidebar';

function App() {

  return (
<div className="App">
  <Sidebar />
</div>
  );
}

export default App;
